input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}
html::-webkit-scrollbar {
  display: none;
}

.underline {
  text-decoration: underline !important;
  color: #dd8300 !important;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  background-color: #0c0c0c;
  color: #ffffff;
}

body nav {
  font-family: 'Source Sans Pro', sans-serif;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
